import React from 'react';
import img1 from '../img/1.jpeg';
import img2 from '../img/2.jpeg';
import img3 from '../img/3.jpeg';
import { Image, Typography } from 'antd';
import { motion } from "framer-motion";
const { Paragraph, Text } = Typography;

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div style={{ textAlign: 'center', margin: 10 }}>
        <div style={{textAlign: 'left'}}>
          <Paragraph copyable>ТОВ КЕРУЮЧА КОМПАНІЯ ГЕТЬМАН-1</Paragraph>
          <Paragraph>Код за ЄДРПОУ: <Text copyable>44017359</Text></Paragraph>
          <Paragraph>IBAN: <Text copyable>UA753052990000026001030110821</Text></Paragraph>
          <Paragraph>Банк: <Text copyable>АТ КБ "ПРИВАТБАНК"</Text></Paragraph>
          <Paragraph>Контактний телефон: <Text copyable><a href='tel: +380500401898'>+38 (050)-040-18-98</a></Text></Paragraph>
        </div>
        <Image.PreviewGroup
          items={[
            img1,
            img2,
            img3,
          ]}
        >
          <Image
            width={'50%'}
            src={img1}
          />
        </Image.PreviewGroup>
      </div>
    </motion.div>
  );
};
export default About;